<template>
  <div class="flex">
    <canvas
      ref="cursorCanvas"
      class="cursor_can"
      :width="width"
      :height="height"
      @mousemove="crossHairMousemove"
      @mouseleave="crossHairMouseLeave"
      @mousedown="mouseDownHandler"
      @mouseup="mouseUpHandler"
      @dblclick="$emit('open_prop')"  
      :style="{ 'margin-left': x + 'px', 'margin-top': y + 'px' }"
    ></canvas>
    <canvas :ref="id" :width="totl_width" :height="totl_height"></canvas>

    <div
      v-if="tooltip"
      class="tooltip textColorClass"
      :style="{ left: tooltip.left + 'px', top: tooltip.top + 'px',color:'#ffffff' }"
    >
      {{ tooltip.content }}
    </div>
  </div>
</template>
<script>
import e from "cors";
import { scaleLinear, zoom, select, scaleTime } from "d3";
import moment from "moment/src/moment";
export default {
  emits: ['xCoordsAfterZoom'],
  name: "CanvasXyAxisLineChart",
  props: {
    totl_width: {
      type: Number,
      required: false,
      default: 600,
    },
    totl_height: {
      type: Number,
      required: false,
      default: 300,
    },
    data_set: {
      type: Array,
      required: true,
    },
    data_set_secondary: {
      type: Array,
      required: true,
    },
    min_X: {
      type: Number,
      default: 0,
    },
    min_Y: {
      type: Number,
      default: 0,
    },
    min_Y_Secondary: {
      type: Number,
      default: 0,
    },
    max_X: {
      type: Number,
      default: 100,
    },
    max_Y: {
      type: Number,
      default: 100,
    },
    max_Y_Secondary: {
      type: Number,
      default: 100,
    },
    id: {
      type: String,
      default: "mycanvasId",
    },
    logType: {
      type: String,
      default: "time",
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    enableTooltip: {
      type: Boolean,
      default: false,
    },
    primaryAxixX: {
      type: Object,
      default: null,
    },
    primaryAxixY: {
      type: Object,
      default: null,
    },
    secondaryAxixY: {
      type: Object,
      default: null,
    },
    enableSecondaryYaxis: {
      type: Boolean,
      default: false,
    },
    primaryAxisLabelColor: {
      type: String,
      default: "",
    },
    secondaryAxisLabelColor: {
      type: String,
      default: "",
    },
    gridColor: {
      type: String,
      default: "",
    },
    primaryGrapgColor: {
      type: String,
      default: "",
    },
    secondaryGrapgColor: {
      type: String,
      default: "",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSizeSecondary: {
      type: Number,
    },
    primaryYaxisUnit:{
      type:String,
      default:''
    },
    secondryYaxisUnit:{
      type:String,
      default:''
    },
    primaryYaxisUnitFactor: {
      type: [String,Number],
      default: "",
    },
    secondaryYaxisUnitFactor: {
      type: [String,Number],
      default: "",
    },
  },
  data() {
    return {
      minX: 0,
      minY: 0,
      minYsecondary: 0,
      maxX: 0,
      maxY: 0,
      maxYsecondary: 0,
      yAxisTitle: "Y-Title",
      secondaryYaxisTitle: "Y-Title",
      xAxisTitle: "X-Title",

      padding: 20,
      paddingFromRight: 40,
      tickSize: 6,
      pointRadius: 5,
      font: "10pt Calibri",
      fontHeight: 12,
      canvas: null,
      context: null,
      tooltip: null,
      mouseX: 0,
      isResetZoomBtnEnable: false,
      zoomed_X_Axis: null,
      zoomed_Y_Axis: null,
      zoomed_secondary_Y_Axis: null,
      cursorCanvas: null,
      cursorContext: null,
      width: 0,
      height: 0,
      mousedown: false,
      selectionStart: {
        x: 0,
        y: 0,
      },
      selectionEnd: {
        x: 0,
        y: 0,
      },
      isDragged:false,
      xCoord: 0,
      yCoord: 0,
    };
  },
  computed: {
    numXTicks() {
      return 10;
    },
    numYTicks() {
      return 10;
    },
    x() {
      return this.padding * 4;
    },
    y() {
      return this.padding;
    },
    // width() {
    //   return this.canvas.width - this.x - this.padding * 2;
    // },
    // height() {
    //   return this.canvas.height - this.y - this.padding * 3 - this.fontHeight;
    // },

    xScale() {
      if (this.logType == "time") {
        return scaleTime()
          .domain([this.minX, this.maxX])
          .range([this.x, this.x + this.width]);
      } else {
        return scaleLinear()
          .domain([this.minX, this.maxX])
          .range([this.x, this.x + this.width]);
      }
    },
    yScale() {
      return scaleLinear()
        .domain([this.minY, this.maxY])
        .range([this.y, this.y + this.height]);
    },
    yScaleSecondary() {
      return scaleLinear()
        .domain([this.minYsecondary, this.maxYsecondary])
        .range([this.y, this.y + this.height]);
    },
    axisColor() {
      return getComputedStyle(this.canvas).getPropertyValue("--textColor");
    },
  },
  methods: {
    mouseDownHandler(event) {
      if (!this.enableZoom) return;
      const rect = this.cursorCanvas.getBoundingClientRect();
      this.selectionStart = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
      this.mousedown = true;
    },
    mouseUpHandler() {
      if (!this.enableZoom) return;
      if(this.isDragged){
        this.handleSelectionEnd();
      }
       
      this.mousedown = false;
      this.cursorContext.clearRect(
        0,
        0,
        this.cursorCanvas.width,
        this.cursorCanvas.height
      ); //clear canvas
      this.isDragged=false;
    },
    zoomed({ transform }) {
      if (!this.enableZoom) return;
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // Update scales with zoom
      const new_xScale = transform.rescaleX(this.xScale);
      const new_yScale = transform.rescaleY(this.yScale);
      const new_secondary_yScale = transform.rescaleY(this.yScaleSecondary);

      this.zoomed_X_Axis = new_xScale;
      this.zoomed_Y_Axis = new_yScale;
      this.zoomed_secondary_Y_Axis = new_secondary_yScale;
      // Update the scales
      let minX = new_xScale.domain()[0];
      let maxX = new_xScale.domain()[1];
      let minY = new_yScale.domain()[0];
      let maxY = new_yScale.domain()[1];
      let minYsecondary = new_secondary_yScale.domain()[0];
      let maxYsecondary = new_secondary_yScale.domain()[1];
      this.$emit('xCoordsAfterZoom', this.minX, this.maxX);
      // Clear and redraw the chart with the updated scales
      this.clearCanvas();
      this.updateScale();
      this.data_set.forEach((d) => {
        if (d.data?.length > 0) {
          let filteredData = d.data.filter(
            (element) =>
              minX < Number(element.x) &&
              Number(element.x) < maxX &&
              minY < Number(element.y) &&
              maxY > Number(element.y)
          );
          this.drawLine(filteredData, d.color, d.width, d.lineType);
        }
      });
      if (this.enableSecondaryYaxis) {
        this.data_set_secondary.forEach(async (d) => {
          if (d.data?.length > 0){
            let filteredData = d.data.filter(
              (element) =>
                minX < Number(element.x) &&
                Number(element.x) < maxX &&
                minYsecondary < Number(element.y) &&
                maxYsecondary > Number(element.y)
            );
            await this.drawLine(filteredData, d.color, d.width, d.lineType, true);
          }
        });
      }
      this.isResetZoomBtnEnable = true;
    },
    async inItChat() {
      this.minX = this.min_X;
      this.minY = this.min_Y;
      this.minYsecondary = this.min_Y_Secondary;
      this.maxX = this.max_X;
      this.maxY = this.max_Y;
      this.maxYsecondary = this.max_Y_Secondary;
      const zoom1 = zoom()
        .scaleExtent([1, 50])
        .translateExtent([
          [this.x, this.y],
          [this.width, this.height],
        ])
        .extent([
          [this.x, this.y],
          [this.width, this.height],
        ])
        .on("zoom", this.zoomed);

      select(this.cursorCanvas).call(zoom1);

      await this.drawXAxis();
      await this.drawYAxis();
      if (this.enableSecondaryYaxis) {
        await this.drawSecondaryYAxis();
        this.data_set_secondary.forEach(async (d) => {
          if (d.data?.length > 0){
            let filteredData = d.data.filter(
              (element) =>
                this.minX < Number(element.x) &&
                Number(element.x) < this.maxX &&
                this.minYsecondary < Number(element.y) &&
                this.maxYsecondary > Number(element.y)
            );
            // await this.drawLine(d.data, d.color, d.width, d.lineType, true);
            await this.drawLine(filteredData, d.color, d.width, d.lineType, true);
          }
        });
      }

      this.data_set.forEach(async (d) => {
        if (d.data?.length > 0){
          let filteredData = d.data.filter(
              (element) =>
                this.minX < Number(element.x) &&
                Number(element.x) < this.maxX &&
                this.minY < Number(element.y) &&
                this.maxY > Number(element.y)
            );
          // await this.drawLine(d.data, d.color, d.width, d.lineType);
          await this.drawLine(filteredData, d.color, d.width, d.lineType);
        }
      });
    },

    async drawXAxis() {
      let context = this.context;
      context.save();
      context.beginPath();
      context.moveTo(this.x, this.y + this.height);
      context.lineTo(this.x + this.width, this.y + this.height);
      context.strokeStyle = this.axisColor;
      context.lineWidth = 2;
      context.stroke();

      let xAxisScale = this.xScale;
      if (this.zoomed_X_Axis) xAxisScale = this.zoomed_X_Axis;
      
      let xAxisTicks = xAxisScale.ticks(this.numXTicks);
      // let xAxisTicks = this.xScale.ticks(this.numXTicks);
      xAxisTicks.forEach((tick) => {
        const xPos = xAxisScale(tick);
        //Draw tick & label
        context.beginPath();
        context.strokeStyle = this.axisColor;
        context.moveTo(xPos, this.y + this.height);
        context.lineTo(xPos, this.y + this.height + this.tickSize);
        context.stroke();

        context.font = this.font;
        context.fillStyle = this.axisColor;
        context.textAlign = "center";
        context.textBaseline = "middle";
        // console.log('time:',tick);
        let fTick = this.logType == "time" ? moment(tick).format("h:mm") : tick;
        context.fillText(fTick, xPos, this.y + this.height + this.padding);
        //add grig gridlines
        // context.closePath();
        // context.beginPath();
        // context.moveTo(xPos, this.y);
        // context.lineTo(xPos, this.y + this.height);
        // context.strokeStyle = this.gridColor == "" ? "#ddd" : this.gridColor;
        // context.lineWidth = 0.5;
        // context.setLineDash([5, 5]);
        // context.stroke();
        // context.closePath();
      });
      context.fillStyle =
          this.primaryAxisLabelColor === ""
            ? this.axisColor
            : this.primaryAxisLabelColor;
      // context.rotate(-Math.PI / 2);
      // context.fillText(
      //   this.primaryAxixY?.name?.toUpperCase() || this.yAxisTitle?.toUpperCase(),
      //   -this.y - this.height / 2 + 20,
      //   this.padding - 5
      // );
      context.font = this.xAxisLabelSize
          ? `${this.xAxisLabelSize}pt Calibri`
          : this.font;
      let textWidth = context.measureText(this.logType?.toUpperCase() || this.xAxisTitle?.toUpperCase()).width
      context.fillText(
        this.logType?.toUpperCase() || this.xAxisTitle?.toUpperCase(),
        this.width / 2 + this.x - textWidth/2,
        this.height + 65
      );

      context.restore();
    },

    async drawYAxis() {
      let context = this.context;
      context.save();
      context.beginPath();
      context.moveTo(this.x, this.y);
      context.lineTo(this.x, this.y + this.height);
      context.strokeStyle = this.axisColor;
      context.lineWidth = 2;
      context.stroke();

      let yAxis = this.yScale;
      if (this.zoomed_Y_Axis) yAxis = this.zoomed_Y_Axis;

      const tickValues = yAxis.ticks(this.numYTicks);

      tickValues.forEach((tick, i) => {
        const yPos = yAxis(tick);
        //Draw tick & label
        context.beginPath();
        context.strokeStyle = this.axisColor;
        context.moveTo(this.x - this.tickSize, yPos);
        context.lineTo(this.x, yPos);
        context.stroke();

        context.font = this.font;
        context.fillStyle = this.axisColor;
        context.textAlign = "right";
        context.textBaseline = "middle";
        context.fillText(this.formatNumber(tick), this.x - this.padding, yPos);
        context.closePath();
        //Draw gridlines

        context.beginPath();
        context.moveTo(this.x, yPos);
        context.lineTo(this.x + this.width, yPos);
        context.strokeStyle = this.gridColor == "" ? "#ddd" : this.gridColor;
        context.lineWidth = 0.5;
        context.setLineDash([5, 5]);
        context.stroke();
        context.closePath();
      });
      context.fillStyle =
          this.primaryAxisLabelColor === ""
            ? this.axisColor
            : this.primaryAxisLabelColor;
      // context.fillText(
      //   this.logType?.toUpperCase() || this.xAxisTitle?.toUpperCase(),
      //   this.width / 2 + this.x,
      //   this.height + 65
      // );
      context.rotate(-Math.PI / 2);
      context.font = this.yAxisLabelSize
          ? `${this.yAxisLabelSize}pt Calibri`
          : this.font;
      let textWidth = context.measureText((this.primaryAxixY?.editedName?.toUpperCase() || this.primaryAxixY?.name?.toUpperCase() || this.yAxisTitle?.toUpperCase()) + `${this.primaryYaxisUnit ? '('+this.primaryYaxisUnit+')' : ''}`).width
      let titleWithUnit = this.primaryAxixY?.editedName?.toUpperCase() || this.primaryAxixY?.name?.toUpperCase() || this.yAxisTitle?.toUpperCase()
      context.fillText(`${titleWithUnit} ${this.primaryYaxisUnit ? '('+this.primaryYaxisUnit+')' : ''}`,
        -this.y - this.height / 2 + textWidth/2,
        this.padding - 5
      );
      context.restore();
    },

    async drawSecondaryYAxis() {
      const context = this.context;
      context.save();
      context.beginPath();
      context.moveTo(this.width + this.x, this.y);
      context.lineTo(this.width + this.x, this.y + this.height);
      context.strokeStyle = this.axisColor;
      context.lineWidth = 2;
      context.stroke();

      let secondaryYAxis = this.yScaleSecondary;
      if(this.zoomed_secondary_Y_Axis) secondaryYAxis = this.zoomed_secondary_Y_Axis
      const secondaryTickValues = secondaryYAxis.ticks(this.numYTicks);

      secondaryTickValues.forEach((tick, i) => {
        const yPos = secondaryYAxis(tick);
        // Draw tick & label for secondary y-axis
        context.beginPath();
        context.strokeStyle = this.axisColor;
        context.moveTo(this.width + this.x, yPos);
        context.lineTo(this.width + this.x + this.tickSize, yPos);
        context.stroke();

        context.font = this.font;
        context.fillStyle = this.axisColor;
        context.textAlign = "left";
        context.textBaseline = "middle";
        context.fillText(this.formatNumber(tick), this.width + this.x + this.padding, yPos);
        context.closePath();

        // Draw gridlines for secondary y-axis
        // context.beginPath();
        // context.moveTo(this.x, yPos);
        // context.lineTo(this.width, yPos);
        // context.strokeStyle = "#ddd";
        // context.lineWidth = 0.5;
        // context.setLineDash([5, 5]);
        // context.stroke();
        // context.closePath();
      });
      context.font = this.yAxisLabelSizeSecondary
          ? `${this.yAxisLabelSizeSecondary}pt Calibri`
          : this.font;
      let textWidth = context.measureText((this.secondaryAxixY?.editedName?.toUpperCase() || this.secondaryAxixY?.name?.toUpperCase() || "Secondary Y-Title") + `${this.secondryYaxisUnit ? '('+this.secondryYaxisUnit+')' : ''}`).width
      context.translate(
        this.width + this.x + this.padding + this.paddingFromRight + 5,
        this.height / 2 + this.padding + textWidth/2
      );
      context.rotate(-Math.PI / 2);
      context.fillStyle =
          this.secondaryAxisLabelColor == ""
            ? this.axisColor
            : this.secondaryAxisLabelColor;
      let titleWithUnit =this.secondaryAxixY?.editedName?.toUpperCase() ||  this.secondaryAxixY?.name?.toUpperCase() || "MNEMONIC";       
      context.fillText(`${titleWithUnit} ${this.secondryYaxisUnit ? '('+this.secondryYaxisUnit+')' : ''}`, 0, 0);
      context.restore();
    },

    async drawLine(data, color, width, lineType = null, isSecndary = false) {
      let linecolor = color;
      if (isSecndary) {
        linecolor =
          this.secondaryGrapgColor == "" ? linecolor : this.secondaryGrapgColor;
      } else {
        linecolor =
          this.primaryGrapgColor == "" ? linecolor : this.primaryGrapgColor;
      }
      let context = this.context;
      context.save();
      context.lineWidth = width;
      context.strokeStyle = linecolor;
      context.fillStyle = linecolor;
      context.beginPath();

      let xScale = this.xScale;
      let yScale = isSecndary ? this.yScaleSecondary : this.yScale;
      if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
      if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;
      if (isSecndary && this.zoomed_secondary_Y_Axis) yScale = this.zoomed_secondary_Y_Axis;

      if (data.length > 0) {
        // Move to the starting point of the line
        const startX = Math.max(0, Math.min(this.width, xScale(data[0].x)));
        const startY = Math.max(0, Math.min(this.height, yScale(data[0].y)));
        context.moveTo(startX, startY);

        if (lineType) context.setLineDash(lineType);

        // Draw the line segment by segment
        for (var n = 1; n < data.length; n++) {
          var point = data[n];
          // Ensure the y-coordinate is within the chart area
          context.lineTo(xScale(point.x), yScale(point.y));
        }
      }

      // Stroke and close the path
      context.stroke();
      context.closePath();

      context.restore();
      context.setLineDash([]);
    },

    async updateScale() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      await this.drawXAxis();
      await this.drawYAxis();
      if (this.enableSecondaryYaxis) {
        await this.drawSecondaryYAxis();
      }
    },

    handleSelectionStart(event) {
      if (!this.enableZoom) return;
      // Track the starting point of the mouse drag
      const rect = this.canvas.getBoundingClientRect();
      this.selectionStart = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
      this.selectionEnd = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
      this.isSelecting = true;
    },
    handleSelectionMove(event) {
      this.handleMouseMoveForTooltip(event);
      // if (!this.enableZoom) {
      //   this.handleMouseMoveForTooltip(event);
      //   return;
      // }
      // if (this.isSelecting) {
      //   // Track the current mouse position during the drag
      //   const rect = this.canvas.getBoundingClientRect();
      //   this.selectionEnd = {
      //     x: event.clientX - rect.left,
      //     y: event.clientY - rect.top,
      //   };
      //   this.drawSelectionRectangle();
      // }
    },
    handleSelectionEnd() {
      // Calculate the zoom area based on the selected rectangle
      const selectedArea = {
        minX: Math.min(
          this.selectionStart.x + this.x,
          this.selectionEnd.x + this.x
        ),
        minY: Math.max(
          this.selectionStart.y + this.y,
          this.selectionEnd.y + this.y
        ),
        maxX: Math.max(
          this.selectionStart.x + this.x,
          this.selectionEnd.x + this.x
        ),
        maxY: Math.min(
          this.selectionStart.y + this.y,
          this.selectionEnd.y + this.y
        ),
      };
      console.log("selectedArea", selectedArea);
      // Calculate the new scales based on the selected area
      const newMinX = this.xScale.invert(selectedArea.minX);
      const newMaxX = this.xScale.invert(selectedArea.maxX);
      const newMinY = this.yScale.invert(selectedArea.maxY);
      const newMaxY = this.yScale.invert(selectedArea.minY);
      const newMinYsecondary = this.yScaleSecondary.invert(selectedArea.maxY);
      const newMaxYsecondary = this.yScaleSecondary.invert(selectedArea.minY);

      // Update the scales
      this.minX = newMinX;
      this.maxX = newMaxX;
      this.minY = newMinY;
      this.maxY = newMaxY;
      this.$emit('xCoordsAfterZoom', this.minX, this.maxX);
      if (this.enableSecondaryYaxis) {
        this.minYsecondary = newMinYsecondary;
        this.maxYsecondary = newMaxYsecondary;
      }
      // Clear and redraw the chart with the updated scales
      this.clearCanvas();
      this.updateScale();
      this.data_set.forEach((d) => {
        if (d.data?.length > 0) {
          let filteredData = d.data.filter(
            (element) =>
              this.minX < Number(element.x) &&
              Number(element.x) < this.maxX &&
              this.minY < Number(element.y) &&
              this.maxY > Number(element.y)
          );
          this.drawLine(filteredData, d.color, d.width);
        }
      });
      if (this.enableSecondaryYaxis) {
        this.data_set_secondary.forEach((d) => {
          if (d.data?.length > 0) {
            let filteredData = d.data.filter(
              (element) =>
                this.minX < Number(element.x) &&
                Number(element.x) < this.maxX &&
                this.minYsecondary < Number(element.y) &&
                this.maxYsecondary > Number(element.y)
            );
            this.drawLine(filteredData, d.color, d.width, d.lineType, true);
          }
        });
      }
    },

    drawSelectionRectangle() {
      // Draw the selection rectangle on the canvas
      const context = this.context;
      context.fillStyle = "rgba(0, 0, 255, 0.2)";
      context.fillRect(
        this.selectionStart.x,
        this.selectionStart.y,
        this.selectionEnd.x - this.selectionStart.x,
        this.selectionEnd.y - this.selectionStart.y
      );
    },

    clearCanvas() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    async resetZoom() {
      this.zoomed_X_Axis = null;
      this.zoomed_Y_Axis = null; // Reset the zoom to the original scale
      this.zoomed_secondary_Y_Axis = null; // Reset the zoom to the original scale
      this.minX = this.min_X;
      this.minY = this.min_Y;
      this.maxX = this.max_X;
      this.maxY = this.max_Y;
      if (this.enableSecondaryYaxis) {
        this.minYsecondary = this.min_Y_Secondary;
        this.maxYsecondary = this.max_Y_Secondary;
      }

      // Redraw the chart with the updated scales
      await this.updateScale();
      this.data_set.forEach(async (d) => {
        if (d.data?.length > 0){
          let filteredData = d.data.filter(
            (element) =>
              this.minX < Number(element.x) &&
              Number(element.x) < this.maxX &&
              this.minY < Number(element.y) &&
              this.maxY > Number(element.y)
          );
          await this.drawLine(filteredData, d.color, d.width, d.lineType);
        }
      });
      if (this.enableSecondaryYaxis) {
        this.data_set_secondary.forEach(async (d) => {
          if (d.data?.length > 0){
            let filteredData = d.data.filter(
              (element) =>
                this.minX < Number(element.x) &&
                Number(element.x) < this.maxX &&
                this.minYsecondary < Number(element.y) &&
                this.maxYsecondary > Number(element.y)
            );
            // await this.drawLine(d.data, d.color, d.width, d.lineType, true);
            await this.drawLine(filteredData, d.color, d.width, d.lineType, true);
          }
        });
      }
    },

    handleMouseMoveForTooltip(event) {
      const rect = this.canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      // Perform logic to find the data point corresponding to the mouse coordinates
      const dataPoint = this.findDataPoint(mouseX, mouseY);

      if (dataPoint) {
        // Use transformed coordinates for tooltip positioning
        const transformedCoordinates = this.transformContextForMouseCoords(
          mouseX,
          mouseY
        );

        // Update tooltip position and content
        this.tooltip = {
          left: transformedCoordinates.x + 10,
          top: this.canvas.height - transformedCoordinates.y - 10, // Adjust for bottom to top display
          content: `${dataPoint.name ? dataPoint.name : "X"}: ${
            dataPoint.x.toFixed(2)
          }, MD: ${dataPoint.y.toFixed(2)}`,
        };
      } else {
        // Hide tooltip if no data point is found
        this.tooltip = null;
      }
    },

    transformContextForMouseCoords(mouseX, mouseY) {
      const context = this.context;
      context.save();
      context.translate(this.x, this.y + this.height);
      context.scale(1, -1); // Flip the Y-axis scaling factor
      const transformedX = mouseX - this.x;
      const transformedY = this.height - (mouseY - this.y);
      context.restore();

      return { x: transformedX, y: transformedY };
    },

    findDataPoint(mouseX, mouseY) {
      // Iterate through your data points to find the one closest to the mouse coordinates
      for (const dataset of this.data_set) {
        for (const dataPoint of dataset.data) {
          let xScale = this.xScale;
          let yScale = this.yScale;
          if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
          if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;

          const x = xScale(dataPoint.x);
          const y = yScale(dataPoint.y);
          const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

          // Adjust the threshold based on your requirements
          if (distance < 10) {
            return dataPoint;
          }
        }
      }

      return null; // No data point found
    },
    findSecondaryPoint(mouseX, mouseY) {
      // Iterate through your data points to find the one closest to the mouse coordinates
      for (const dataset of this.data_set_secondary) {
        for (const dataPoint of dataset.data) {
          let xScale = this.xScale;
          let yScale = this.yScaleSecondary;
          if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
          if (this.zoomed_secondary_Y_Axis) yScale = this.zoomed_secondary_Y_Axis;

          const x = xScale(dataPoint.x);
          const y = yScale(dataPoint.y);
          const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

          // Adjust the threshold based on your requirements
          if (distance < 10) {
            return dataPoint;
          }
        }
      }

      return null; // No data point found
    },
    formatNumber(number) {
      const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
      let suffixIndex = 0;

      while (Math.abs(number) >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
      }

      return number.toFixed(1) + suffixes[suffixIndex];
    },
    crossHairMousemove(event) {
      const rect = this.cursorCanvas.getBoundingClientRect();
      const x = event.pageX - rect.left;
      const y = event.pageY - rect.top;
      this.xCoord = event.pageX;
      this.yCoord = event.pageY;
      if (this.enableTooltip) {
        this.cursorContext.clearRect(
          0,
          0,
          this.cursorCanvas.width,
          this.cursorCanvas.height
        );
        this.cursorContext.beginPath();
        this.cursorContext.moveTo(0, y);
        this.cursorContext.lineTo(this.width, y);
        this.cursorContext.moveTo(x, 0);
        this.cursorContext.lineTo(x, this.width);
        this.cursorContext.strokeStyle = "#ddd";
        this.cursorContext.stroke();
        this.cursorContext.closePath();
        let dataPoint =
          this.findDataPoint(x + this.x, y + this.y) ||
          this.findSecondaryPoint(x + this.x, y + this.y);
        if (dataPoint) {
          // Use transformed coordinates for tooltip positioning
          const transformedCoordinates = this.transformContextForMouseCoords(
            x + 20,
            y + 20
          );

          // Update tooltip position and content
          this.tooltip = {
            left: transformedCoordinates.x + 10,
            top: this.canvas.height - transformedCoordinates.y - 10, // Adjust for bottom to top display
            content: `${this.logType}: ${
              this.logType == "time"
                ? moment(dataPoint.x).format("MM-DD-YYYY h:mm:ss")
                : dataPoint.x
            }, ${dataPoint.name ? dataPoint.name : "Y"}: ${dataPoint.y.toFixed(2)}`,
          };
          let secondaryDataPoint = this.findSecondaryPoint(x + this.x, y + this.y);
          if(secondaryDataPoint && dataPoint.name != secondaryDataPoint.name){
            this.tooltip.content += `, ${secondaryDataPoint.name ? secondaryDataPoint.name : "Y"}: ${secondaryDataPoint.y.toFixed(2)}`
          }
        } else {
          // Hide tooltip if no data point is found
          this.tooltip = null;
        }
      }
      // if (this.mousedown && this.enableZoom) {
      //   this.cursorContext.clearRect(
      //     0,
      //     0,
      //     this.cursorCanvas.width,
      //     this.cursorCanvas.height
      //   );
      //   this.cursorContext.beginPath();
      //   var width = x - this.selectionStart.x;
      //   var height = y - this.selectionStart.y;
      //   this.cursorContext.rect(
      //     this.selectionStart.x,
      //     this.selectionStart.y,
      //     width,
      //     height
      //   );
      //   this.cursorContext.fillStyle = "#8ED6FF";
      //   this.cursorContext.globalAlpha = 0.5;
      //   this.cursorContext.fill();
      //   this.cursorContext.lineWidth = 1;
      //   this.cursorContext.strokeStyle = "#ddd";
      //   this.cursorContext.stroke();
      //   this.selectionEnd.x = x;
      //   this.selectionEnd.y = y;
      //   this.cursorContext.globalAlpha = 1;
      //   this.isDragged=true;
      // }
    },
    crossHairMouseLeave() {
      this.xCoord = 0;
      this.yCoord = 0;
      if (!this.enableTooltip) return;
      this.tooltip = null;
      this.selectionEnd.x = 0;
      this.selectionEnd.y = 0;
      this.selectionStart.x = 0;
      this.selectionStart.y = 0;
      this.cursorContext.clearRect(
        0,
        0,
        this.cursorCanvas.width,
        this.cursorCanvas.height
      );
      this.isDragged=false;
    },
  },
  mounted() {
    console.log('Mounted xy-canvas');
    this.canvas = this.$refs[this.id];
    this.context = this.canvas.getContext("2d");
    this.width =
      this.canvas.width - this.x - this.padding * 2 - this.paddingFromRight;
    // this.width =
    //   this.canvas.width - this.x - this.padding * 2;
    this.height =
      this.canvas.height - this.y - this.padding * 3 - this.fontHeight;
    this.cursorCanvas = this.$refs.cursorCanvas;
    this.cursorContext = this.cursorCanvas.getContext("2d");

    this.inItChat();
  },
  watch: {
    data_set: {
      async handler(newValue, oldValue) {
        await newValue.forEach(async (d) => {
          if (d.data?.length > 0) await this.updateScale();
          await this.drawLine(d.data, d.color, d.width, d.lineType);
        });
        if (this.enableSecondaryYaxis) {
          await this.data_set_secondary.forEach(async (d) => {
            if (d.data?.length > 0)
              await this.drawLine(d.data, d.color, d.width, d.lineType, true);
          });
        }
        await this.crossHairMousemove({pageX: this.xCoord, pageY: this.yCoord});
      },
      deep: true,
    },
    data_set_secondary: {
      async handler(newValue, oldValue) {
        await newValue.forEach(async (d) => {
          if (d.data?.length > 0) await this.updateScale();
          await this.drawLine(d.data, d.color, d.width, d.lineType, true);
        });
        await this.data_set.forEach(async (d) => {
          if (d.data?.length > 0)
            await this.drawLine(d.data, d.color, d.width, d.lineType);
        });
        await this.crossHairMousemove({pageX: this.xCoord, pageY: this.yCoord});
      },
      deep: true,
    },
    min_X(val) {
      this.minX = val;
      // this.updateScale();
    },
    min_Y(val) {
      this.minY = val;
      // this.updateScale();
    },
    min_Y_Secondary(val) {
      this.minYsecondary = val;
      // this.updateScale();
    },
    max_X(val) {
      this.maxX = val;
      // this.updateScale();
    },
    max_Y(val) {
      this.maxY = val;
      // this.updateScale();
    },
    max_Y_Secondary(val) {
      this.maxYsecondary = val;
      // this.updateScale();
    },
    async logType(val) {
      await this.updateScale();
      this.xAxisTitle = val;
    },
    primaryAxixY(val) {
      if (val) {
        this.yAxisTitle = val.name;
      }
    },
    async enableSecondaryYaxis() {
      await this.updateScale();
    },
  },
  expose: ["resetZoom"],
};
</script>
<style scoped>
.zoombtn {
  margin-top: 5px;
  font-size: 10px;
}
.tooltip {
  position: absolute;
  background-color: rgba(49, 46, 46, 0.8);
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  pointer-events: none;
  z-index: 9999999;
}
.cursor_can {
  position: absolute;
  z-index: 999999;
}
</style>
